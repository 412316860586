<template>
  <div class="calculation section">
    <div class="container calculation__btns">
      <router-link
        :to="{ name: 'record_list_plus' }"
        class="calculation__btns-item"
      >
        <i class="fas fa-plus"></i>
      </router-link>
      <router-link
        :to="{ name: 'record_list_pro' }"
        class="calculation__btns-item calculation__btns-item_sm"
      >
        <span>
          <i class="fas fa-plus"></i>
        </span>
        <span></span>
        <span>
          <i class="fas fa-minus"></i>
        </span>
      </router-link>
      <router-link
        :to="{ name: 'record_list_multiply' }"
        class="calculation__btns-item"
      >
        <i class="fas fa-times"></i>
      </router-link>
      <router-link
        :to="{ name: 'record_list_divide' }"
        class="calculation__btns-item"
      >
        <i class="fas fa-slash" style="transform: rotate(90deg)"></i>
      </router-link>
      <router-link
        :to="{ name: 'record_list_root' }"
        class="calculation__btns-item"
      >
        <i class="fas fa-square-root-alt"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
};
</script>
